import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';

import DashboardLayout from 'src/layouts/DashboardLayout';
import OnBoardingLayout from 'src/layouts/OnBoardingLayout';
import AuthGuard from 'src/components/guards/AuthGuard';
import GuestGuard from 'src/components/guards/GuestGuard';

import { LocalizedSwitch } from './LocalizedSwitch';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/login/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password',
    component: lazy(() => import('src/views/auth/reset-password/ResetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/registration/RegisterView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/email-validation',
    component: lazy(() => import('src/views/auth/email-validation/EmailValidationView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/keyring',
    component: lazy(() => import('src/views/auth/keyring/KeyringView'))
  },
  {
    exact: true,
    path: '/onboarding',
    guard: AuthGuard,
    layout: OnBoardingLayout,
    component: lazy(() => import('src/views/onboarding/OnBoardingView'))
  },
  {
    exact: true,
    path: '/passphrase',
    guard: AuthGuard,
    layout: OnBoardingLayout,
    component: lazy(() => import('src/views/onboarding/OnBoardingView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/space-select',
    component: lazy(() => import('src/views/auth/spaces/SelectSpaceView'))
  },
  {
    exact: true,
    path: '/albums/:key',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('src/views/common/album/AlbumView')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/media/:mediaId',
    component: lazy(() => import('src/views/common/media/MediaView')),
  },
  {
    exact: true,
    path: '/folders/:key',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('src/views/common/folder/FolderView')),
  },
  {
    exact: true,
    path: '/profile',
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import('src/views/account/AccountView')),
  },
  {
    exact: true,
    path: '/subscriptions/:sku',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/subscriptions/SubscriptionPaymentView')),
  },
  {
    path: '/kids',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/kids',
        component: lazy(() => import('src/views/kids/home/HomeView'))
      },
      {
        exact: true,
        path: '/kids/subscriptions',
        component: lazy(() => import('src/views/subscriptions/SubscriptionsView'))
      },
      {
        exact: true,
        path: '/kids/members',
        component: lazy(() => import('src/views/kids/members/MemberView'))
      },
      {
        exact: true,
        path: '/kids/albums',
        component: lazy(() => import('src/views/kids/safes/albums')),
      },
      {
        exact: true,
        path: '/kids/documents',
        component: lazy(() => import('src/views/kids/safes/documents'))
      },
      {
        exact: true,
        path: '/kids/timelapse',
        component: lazy(() => import('src/views/kids/safes/timelapse/TimelapseView'))
      },
      {
        exact: true,
        path: '/kids/savings',
        component: lazy(() => import('src/views/kids/safes/savings/SavingsView'))
      },
      {
        exact: true,
        path: '/kids/pot',
        component: lazy(() => import('src/views/kids/safes/pot/PotView'))
      },
      {
        exact: true,
        path: '/kids/health',
        component: lazy(() => import('src/views/kids/safes/health/HealthView'))
      }, 
      {
        exact: true,
        path: '/kids',
        component: () => <Redirect to="/kids/dashboard" />
      },
    ]
  },
  {
    path: '/classic',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/classic',
        component: lazy(() => import('src/views/classic/home/HomeView'))
      },
      {
        exact: true,
        path: '/classic/subscriptions',
        component: lazy(() => import('src/views/subscriptions/SubscriptionsView'))
      },
      {
        exact: true,
        path: '/classic/members',
        component: lazy(() => import('src/views/classic/members/MemberView'))
      },
      {
        exact: true,
        path: '/classic/credentials',
        component: lazy(() => import('src/views/classic/safes/credentials'))
      },
      {
        exact: true,
        path: '/classic/credentials/edit',
        component: lazy(() => import('src/views/classic/safes/credentials/edit'))
      },
      {
        exact: true,
        path: '/classic/testament',
        component: lazy(() => import('src/views/classic/safes/testament'))
      },
      {
        exact: true,
        path: '/classic/directives',
        component: lazy(() => import('src/views/classic/safes/directives'))
      },
      {
        exact: true,
        path: '/classic/funerals',
        component: lazy(() => import('src/views/classic/safes/funerals'))
      },
      {
        exact: true,
        path: '/classic/memories',
        component: () => <Redirect to="/classic/memories/me" />
      },
      {
        exact: true,
        path: ['/classic/memories/video', '/classic/memories/albums', '/classic/memories/me'],
        component: lazy(() => import('src/views/classic/safes/memories')),
      },
      {
        exact: true,
        path: '/classic/documents',
        component: lazy(() => import('src/views/classic/safes/documents'))
      },
      {
        exact: true,
        path: '/classic',
        component: () => <Redirect to="/classic/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    exact: true,
    path: '/',
    guard: AuthGuard,
    // component: () => <Redirect to="/login" />
  },
  // {
  //   path: '*',
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/app',
  //     },
      // {
      //   exact: true,
      //   path: '/',
      //   component: () => <Redirect to="/login" />
      // },
  //   ]
  // },
  {
    component: () => <Redirect to="/404" />
  }
];


export const buildNavigation = (): JSX.Element => {
  return renderRoutes(routes);
}

const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense
    fallback
    >
    <LocalizedSwitch>
      {
        routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              children={(props) => (
                <Guard>
                  <Layout>
                    { route.routes && renderRoutes(route.routes) }
                    { route.component && <Component {...props} />}
                  </Layout>
                </Guard>
              )}
              // render={(props) => (
              //   <Guard>
              //     <Layout>
              //       {route.routes
              //         ? renderRoutes(route.routes)
              //         : <Component {...props} />}
              //     </Layout>
              //   </Guard>
              // )}
            />
          );
        })
      }
    </LocalizedSwitch>
  </Suspense>
);
