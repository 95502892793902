import React, { useState } from 'react';
import type { FC } from 'react';
import {
  Container,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  Button,
  Box,
  makeStyles,
  DialogActions
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PassphrasePDF from "src/views/classic/passphrase/PassphrasePDF";

import useAuth from 'src/hooks/useAuth';
import type { Theme } from 'src/theme';
import MainButton from 'src/components/MainButton';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));

interface PassphraseViewDialogProps {
  onClose?: () => void;
}

const PassphraseViewDialog: FC<PassphraseViewDialogProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { context } = useAuth();
  const [shouldShowPassphrase, setShouldShowPassphrase] = useState<boolean>(context.mySpace?.keyring?.hasDownloadedPassphrase == false && context.mySpace?.keyring.passphrase != null);
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState<boolean>(shouldShowPassphrase);

  const clickOnDownload = () => {
    console.log("DOWNLOAD");
    context.mySpace.keyring.hasDownloadedPassphrase = true;
    context.mySpace.keyring.passphrase = null;
    context.save();
    setShouldShowPassphrase(false);
  };

  if (shouldShowPassphrase == false) return <></>;

  return (
    <Dialog
      maxWidth="lg"
      onClose={() => {}}
      open={modalOpen}
    >
      <DialogTitle>
        {t('modal_passphrase_action_required')}
      </DialogTitle>
      <Box
        pl={3}
        pr={3}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            // sm={10}
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ fontWeight: "bold" }}
            >
              {t("passphrase_notification_description")}
            </Typography>
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={2}
          >
            <PDFDownloadLink
              document={<PassphrasePDF passphrase={context.mySpace?.keyring?.passphrase} profile={{
                first_name: context.mySpace.user.first_name,
                last_name: context.mySpace.user.last_name,
                dob: context.mySpace.user.dob,
              }}
              />}
              fileName="wishbook_passphrase"
              style={{ textDecoration: 'none' }}
            >
              <MainButton
                fullWidth
                isProcessing={false}
                title={t('onboarding_step_passphrase_download_action')}
                startIcon={<GetAppIcon />}
              />
            </PDFDownloadLink>
          </Grid> */}
        </Grid>
      </Box>

      <DialogActions>
        <PDFDownloadLink
          document={<PassphrasePDF passphrase={context.mySpace?.keyring?.passphrase} profile={{
            first_name: context.mySpace.user.first_name,
            last_name: context.mySpace.user.last_name,
            dob: context.mySpace.user.dob,
          }}
          />}
          fileName="wishbook_passphrase"
          style={{ textDecoration: 'none' }}
        >
          <MainButton
            fullWidth
            isProcessing={false}
            title={t('onboarding_step_passphrase_download_action')}
            startIcon={<GetAppIcon />}
            onClick={clickOnDownload}
          />
        </PDFDownloadLink>
      </DialogActions>
    </Dialog>
  );
};

export default PassphraseViewDialog;
