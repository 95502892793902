import _ from 'lodash';
import { createMuiTheme } from '@material-ui/core';
import type { Theme as MuiTheme } from '@material-ui/core/styles/createMuiTheme';
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground
} from '@material-ui/core/styles/createPalette';

import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';

interface TypeBackground extends MuiTypeBackground {
  dark: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

interface ThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  palette?: Record<string, any>;
  shape?: Record<string, any>;
  shadows?: MuiShadows;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography: {
    fontFamily: 'Poppins',
  },
  overrides: {
    // MuiLinearProgress: {
    //   root: {
    //     borderRadius: 3,
    //     overflow: 'hidden'
    //   }
    // },
    // MuiListItemIcon: {
    //   root: {
    //     minWidth: 32
    //   }
    // },
    // MuiChip: {
    //   root: {
    //     backgroundColor: 'rgba(0,0,0,0.075)'
    //   }
    // },
  }
};

const WISHBOOK_DARK_BLUE = "#222A35";
const WISHBOOK_GOLD = "#DEAC4E";
const WISHBOOK_LIGHT_GOLD = "#FFCF75";
const WISHBOOK_GREY = "#657289";
const WISHBOOK_FREEMIUM_BLUE = "#01989e";

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.WISHBOOK,
    shape: {
      borderRadius: 16,
    },
    overrides: {
      MuiLink: { underlineHover: { color: '#628BC3', } }, // Light blue
      MuiCardHeader: {
        title: {
          color: WISHBOOK_DARK_BLUE,

        },
      },
      MuiPickersYear: {
        root: {
          color: WISHBOOK_DARK_BLUE,
        },
        "&$selected": {
          backgroundColor: "#657289",
        },
      },
      MuiInput: {
        underline: {
          "&:before": {
            borderBottom: `1px solid #C8CACC` // Light grey
          }
        },
      },
      // MuiTypography: {
      //   colorInherit: { color: WISHBOOK_DARK_BLUE },
      //     overline: { color: WISHBOOK_GOLD },
      // },
      MuiSvgIcon: {
        colorPrimary: {
          color: WISHBOOK_LIGHT_GOLD,
        }
      },
      "MuiSelectIcon": {
        root: {
          color: WISHBOOK_DARK_BLUE,
        }
      },
      MuiIconButton: {
        root: {
          color: WISHBOOK_DARK_BLUE // Dark blue
        }
      },
      MuiToggleButton: {
        root: {
          color: WISHBOOK_DARK_BLUE,
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          "&$selected": {
            color: WISHBOOK_DARK_BLUE,
            backgroundColor: WISHBOOK_LIGHT_GOLD,
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: WISHBOOK_DARK_BLUE, //'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#ffffff', //
        // default: "#1A1E24", //
        dark: '#ffffff', //
        paper: '#ffffff',
        // paper: "#55585c"
      },
      primary: {
        light: '#ffffff',
        main: WISHBOOK_DARK_BLUE, // Dark blue
        contrastText:WISHBOOK_GOLD, // Gold color
      },
      secondary: {
        light: '#F4F6F8',
        main: WISHBOOK_GOLD,
        contrastText: WISHBOOK_DARK_BLUE,
      },
      // info: {
      //   main: '#DEAC4E',
      // },
      // success: {
      //   main: '',
      // },
      // warning: {
      //   main: '',
      // },
      // error: {
      //   main: '',
      // },
      // divider: '',
      text: {
        primary: WISHBOOK_DARK_BLUE, // Dark blue
        secondary: WISHBOOK_GREY,
        disabled: "#1A1E24"
      }
    },
    shadows: softShadows,
    typography: {
      h1: {
        color: WISHBOOK_GOLD,
        fontSize: "3rem",
        fontWeight: 700,
      },
      h3: {
        color: WISHBOOK_LIGHT_GOLD,
        fontSize: "2.3rem",
        fontWeight: "bold"
      },
      h4: {
        color: WISHBOOK_DARK_BLUE,
        fontWeight: 600,
        fontSize: "1.8rem"
      },
      h5: { // CardHeader are h5
        color: WISHBOOK_DARK_BLUE,
        fontWeight: 600,
        fontSize: "1.2rem"
      },
      h6: { // CardHeader are h5
        color: WISHBOOK_DARK_BLUE,
        fontWeight: 600,
        fontSize: "1rem"
      },
      body2: {
        color: WISHBOOK_GREY,
        fontSize: "0.92rem"
      },
      subtitle1: {
        color: WISHBOOK_DARK_BLUE,
        fontWeight: 100
      },
      subtitle2: {
        color: WISHBOOK_DARK_BLUE,
      },
    },
  }
];

export const createTheme = (): Theme => {
  
  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themesOptions[0],
    )
  );

  return theme as Theme;
}
