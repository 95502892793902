import React from 'react';
import type { FC } from 'react';
import {
  Box,
  Avatar,
  Typography,
  makeStyles,
} from '@material-ui/core';

import type { Theme } from 'src/theme';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2.5),
    padding: theme.spacing(2),
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    border: "solid",
    borderRadius: theme.shape.borderRadius,
  },
  avatar: {
    width: 34,
    height: 34,
    backgroundColor: theme.palette.secondary.main,
  },
}));

const User: FC = ({}) => {
  const classes = useStyles();
  const { context } = useAuth();

  return (
    <Box display="flex" flexDirection={{ sm: "row" }} className={classes.root}>
    <Box
      flex={{ xs: 1}}
      display="flex"
      alignItems="center"
    >
      <Avatar
          alt="User"
          className={classes.avatar}
        />
    </Box>
    <Box flex={{ xs: 3}}>
      <Typography
        variant="h6"
        color="textPrimary"
      >
        {context.getCurrentSpace().user.first_name}
      </Typography>

      <Typography
        variant="h6"
        color="textPrimary"
      >
        {context.getCurrentSpace().user.last_name}
      </Typography>
    </Box>
  </Box>
  );
};

export default User;