export const APP_VERSION = '3.0.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  WISHBOOK: 'WISHBOOK',
  GUARDIAN: 'GUARDIAN'
};

export const AVAILABLE_THEMES = [
  THEMES.WISHBOOK,
  THEMES.GUARDIAN,
];

export interface LanguageConfiguration {
  key: string;
  name: LanguageNames;
}

export enum LanguageNames  {
  French = 'FRENCH',
  English = 'ENGLISH',
}

export enum LanguageKeys  {
  French = 'fr',
  English = 'en',
}

export const AVAILABLE_LANGUAGES_CONFIGURATIONS: LanguageConfiguration[] = [
  { key: "en", name: LanguageNames.English},
  { key: "fr", name: LanguageNames.French},
];

export const INTERCOM_APP_ID = "f9byex1u";

export const SUPPORT_EMAIL = "contact@wishbook.fr";

/*
** Static external links
*/
export const TERMS_URL = "https://wb-public-s3.s3.eu-west-3.amazonaws.com/Wishbook+-+T%26Cs.pdf";
export const PRIVACY_POLICY_URL = "https://wb-public-s3.s3.eu-west-3.amazonaws.com/Wishbook+-+Privacy+Policy.pdf";
export const DIRECTIVES_URL = "https://sante.gouv.fr/IMG/pdf/2023_04_modele_directives_anticipees.pdf";
export const CALENDLY_URL = "https://calendly.com/wishbook/25";

/*
** Configuration by ENV.
*/
export const UMA_BASEURL = process.env.REACT_APP_UMA_BASEURL || "https://uma-staging.wishbook.world";
export const WISHBOOK_BASEURL = process.env.REACT_APP_WISHBOOK_BASEURL || "https://api-staging.wishbook.world";
export const TUSD_BASEURL = process.env.REACT_APP_TUSD_BASEURL || "https://tusd-staging.wishbook.world";
export const APP_BASE_URL = process.env.REACT_APP_APP_BASE_URL ||  "https://dashboard-staging.wishbook.world";

export const UMA_CLIENT_ID = process.env.REACT_APP_UMA_CLIENT_ID || "AVWHo68cC07Sn8i3xjap1meQJUaMBacs";
export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || "G-1P1EE0DXDP";
export const STRIPE_PUB_KEY = process.env.REACT_APP_STRIPE_PUB_KEY || "pk_test_51I9tD0Ft4lWTBQ6GcOXAtjIJacaEcY1kvaTIplpPYxCVpUDLNgTsZdigi1Y7H0meqhm4TAdrv8p4D1CnpfXXESny00hSxNegoc";

export const WEBSITE_BASE_URL = "https://wishbook.world";
