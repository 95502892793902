import {
  Onboarding,
  OnboardingType,
  OnboardingUserInfo,
} from './model';
import { APP_BASE_URL } from 'src/constants';
import { Configuration, Keyring, UserType } from "../users/model";
import { makeRequest } from '../common/client';
import { SpaceType } from 'src/contexts/AuthContext/Space';
import { MetaType } from '../meta/MetaConfiguration';

import { MetaAlbums, defaultAlbums, addAlbum } from "src/api/wishbook/safes/common/MetaAlbums";
import { MetaDocuments, defaultDocuments, addFolder } from "src/api/wishbook/safes/common/MetaDocuments";

import * as metaApi from "src/api/wishbook/meta/api";
import { PersistentContext } from 'src/contexts/AuthContext/PersistentContext';
import { SubscriptionPaymentProvider } from '../subscriptions/model';

export const getAll = async (): Promise<Onboarding[]> => {
  const response = await makeRequest('GET', '/api/v1/users/me/onboardings');
  return response.data.onboardings;
};

export const getAndUpdate = async (onboardingId: string): Promise<Onboarding> => {
  const response = await makeRequest('GET', `/api/v1/users/me/onboardings/${onboardingId}`);
  return response.data;
};

export const create = async (onboardingType: OnboardingType, onboardingUserInfo?: OnboardingUserInfo, onboardingSubscription?: SubscriptionInput): Promise<Onboarding> => {
  const payload: any = {
    "step_type": onboardingType
  };
  if (onboardingUserInfo) payload.step_userinfo = onboardingUserInfo;
  if (onboardingSubscription) {
    if (onboardingSubscription.callback_url == null) onboardingSubscription.callback_url = `${APP_BASE_URL}/onboarding`;
    payload.step_subscription = onboardingSubscription;
  }

  const response = await makeRequest('POST', '/api/v1/users/me/onboardings', payload);
  return response.data;
};

// export const get = async (obId: string): Promise<Onboarding> => {
//   const response = await makeRequest('GET', `/api/v1/users/me/onboardings/${obId}`);
//   return response.data;
// };

interface SubscriptionInput {
  sku?: string,
  coupon?: string,
  provider?: SubscriptionPaymentProvider;
  callback_url?: string;
};
export const updateSubscription = async (obId: string, subscriptionInput: SubscriptionInput): Promise<Onboarding> => {
  subscriptionInput.callback_url = `${APP_BASE_URL}/onboarding`;
  const response = await makeRequest('POST', `/api/v1/users/me/onboardings/${obId}/update-subscription`, subscriptionInput);
  return response.data;
};

export const updateUserInfo = async (obId: string, userInfo: OnboardingUserInfo): Promise<Onboarding> => {
  const response = await makeRequest('POST', `/api/v1/users/me/onboardings/${obId}/update-userinfo`, userInfo);
  return response.data;
};

interface OnboardingFinalStepClassic {
  step_key?: Keyring,
  step_userinfo?: OnboardingUserInfo,
}
interface OnboardingFinalStepKids {
  step_configuration?: Configuration,
  step_userinfo?: OnboardingUserInfo,
}

export const finalize = async (obId: string, finalStep: OnboardingFinalStepClassic|OnboardingFinalStepKids): Promise<Onboarding> => {
  const response = await makeRequest('POST', `/api/v1/users/me/onboardings/${obId}/finalize`, finalStep);
  return response.data;
};

export const postFinalize = async (context: PersistentContext, onboarding: Onboarding) => {
  if (onboarding.step_type.type === UserType.MEMBER) { return; }

  if (onboarding.step_type.space === SpaceType.CLASSIC && onboarding.step_type.type === UserType.WISHBOOKER) {
    const albumsCollections = __createAlbums(context);
    const documentsCollections = __createDocuments(context);
    await Promise.all([albumsCollections, documentsCollections]);
  }
};

const __createAlbums = async (context: PersistentContext): Promise<void> => {
  const memories: MetaAlbums = defaultAlbums(MetaType.MetaTypeClassicAlbums);
  addAlbum(memories, {
    title: "Enfance",
    description: "Dans cette partie de votre vie il se peut que vous ayez des souvenirs extraordinaires, des lieux qui ont compté, une maison remplie de souvenirs, une photo unique avec un proche que vous avez perdu ensuite, un rêve d’enfant que vous avez concrétisé adulte… A vous de raconter votre histoire !"
  });
  addAlbum(memories, {
    title: "Adolescence / jeune adulte",
    description: "A ce stade, l’adolescence et la vie de jeune adulte peuvent paraître anecdotiques mais vous y avez peut-être fait des rencontres marquantes, eu un look ravageur, passé vos études, commencé à travailler, découvert une passion, eu votre premier appartement, vos premiers coups de coeur… Partagez leur qui vous êtes."
  });
  addAlbum(memories, {
    title: "Adulte",
    description: "Adulte est un grand mot car il est si subjectif ! Dans cette phase de vie il y a sûrement de grandes histoires à raconter sur votre vie : une belle rencontre, une union, des voyages, des challenges de vie, des fêtes avec ceux qu’on aime, l’arrivée des enfants, la famille qui s’agrandit, les amitiés qui traversent les années…"
  });
  addAlbum(memories, {
    title: "Plus de 60 ans",
    description: "A cette étape, la vie est bien remplie et certains disent que “vieillir est un honneur.” Peut-être que vous avez un recul sur la vie qui vous a apporté des bonheurs différents. Pensez aussi à partager vos voyages, la rencontre avec vos petits-enfants, des grandes réunions de famille...Partagez-les avec ceux que vous aimez car votre vécu restera grâce à ces coffres-forts."
  });
  await metaApi.upsert(context, memories);
};

const __createDocuments = async (context: PersistentContext): Promise<any> => {
  const documents: MetaDocuments = defaultDocuments(MetaType.MetaTypeClassicDocuments);

  addFolder(documents, {
    title: "Mon identité",
    description: "",
    tags: [
      "id",
      "livret de famille",
      "arbre généalogique",
      "test ADN"
    ]
  });
  addFolder(documents, {
    title: "Parcours & Réalisations",
    description: "",
    tags: [
      "diplômes",
      "brevets industriel",
      "création",
      "kbis",
      "parution sur vos réalisations",
      "revue de presse",
    ]
  });
  addFolder(documents, {
    title: "Mon patrimoine",
    description: "",
    tags: [
      "actes de propriété",
      "actes notariés",
      "objects détenus avec une histoire à transmettre",
    ]
  });
  addFolder(documents, {
    title: "Santé",
    description: "",
    tags: [
      "important",
    ]
  });
  addFolder(documents, {
    title: "Secrets",
    description: "",
    tags: [
      "j'ai caché",
      "ce que je n'ai pas dit",
    ]
  });
  await metaApi.upsert(context, documents);
};