export const translations: any = {
"error_happened": "An error happened",
"yup_string_max": "Field cannot exceed 255 characters.",
"yup_description_max": "Field cannot exceed 500 characters.",
"yup_password_min": "Field must contain at least 4 characters.",
"yup_phone_format": "Bad phone number format",
"yup_max_default": "Field cannot exceed 1000 characters",
"yup_field_required": "Field is required.",
"yup_min_two": "2 characters minimum",
"modal_confirmation_title": "Confirmation !",
"modal_confirmation_yes": "Yes",
"modal_confirmation_no": "No",
"modal_cancel": "Cancel",
"modal_done": "Finish",
"modal_passphrase_action_required": "Required action",
"base_path_1": "Safes",
"files_dropzone_title": "File selection",
"files_dropzone_description": "Drop your files here or",
"files_dropzone_browse": "browse",
"files_dropzone_side_sentence": "your files",
"files_dropzone_delete_all": "Delete all",
"files_dropzone_upload": "Upload all",
"navbar_space_tooltip": "Click here to change Wishbook space",
"navbar_status_guardian_format": "has named you",
"navbar_status_wishbooker": "Wishbooker",
"navbar_status_guardian": "Guardian",
"navbar_quit_space": "Quit space",
"navbar_switch_space": "Switch espace",
"navbar_question": "A question?",
"navbar_contact_us": "Contact us",
"footer_follow_us": "Follow us",
"navbar_section_0": "WISHBOOKER",
"navbar_section_1": "MY SAFES",
"navbar_dashboard": "Dashboard",
"navbar_subscriptions": "Subscriptions",
"topbar_account_menu_account": "Account",
"topbar_account_menu_logout": "Log out",
"navbar_documents": "Documents",
"navbar_memories": "Memories",
"navbar_funerals": "Funerals wishes",
"navbar_credentials": "Credentials",
"navbar_directives": "Advance directives",
"navbar_testament": "Testament",
"navbar_guardians": "Guardians",
"navbar_albums": "Albums",
"navbar_ff": "Friends & Family",
"navbar_timelapse": "Timelapse video",
"navbar_savings": "Savings",
"navbar_pot": "Pot",
"navbar_health": "Health",
"documents_path_2": "Documents",
"documents_path_3": "Files",
"kids_documents_title": "Important documents",
"kids_documents_description": "Gather important documents: diplomas, certificates, family record book, identity papers, school records... Anything that will be useful to him/her even as an adult",
"documents_title": "Documents",
"documents_folder_title": "Your documents",
"documents_title_description": "Daily documents may seem trivial, but if you had those of your ancestors, imagine what you would know about them. An identity document, a marriage certificate, diplomas, a publication on your achievements, a property deed, an industrial patent ... These documents are testimonies of life.",
"documents_modal_button_title": "New folder",
"documents_modal_title": "Create folder",
"documents_modal_description": "Specify a name and a description for your folder.",
"documents_modal_form_title": "Name",
"documents_modal_form_description": "Description",
"documents_modal_form_description_placeholder": "You can add a description here",
"documents_modal_add_form_success": "New folder created !",
"documents_modal_add_form_failure": "An error happened while creating a new folder",
"documents_modal_form_tags": "Tags",
"documents_modal_form_submit": "Create",
"documents_folder_delete_modal_confirm": "Are you sure to delete this folder?",
"documents_folder_delete_modal_success": "Folder deleted!",
"documents_folder_delete_modal_failure": "An error happened while deleting this folder",
"documents_folder_add_files": "Add files",
"documents_folder_delete": "Delete folder",
"documents_file_upload_modal_title": "Add files",
"documents_file_upload_modal_description": "You can add a file here",
"documents_file_delete_modal_success": "File deleted!",
"documents_file_delete_modal_failure": "An error happened while deleting this file",
"documents_file_delete_modal_confirm_one_file": "Are you sure to delete this file?",
"documents_file_delete_modal_confirm_multiple_files": "Are you sure to delete these files?",
"documents_file_form_edit_label": "Edit",
"documents_file_form_delete_label": "Delete",
"documents_file_list_type": "Type",
"documents_file_list_name": "Name",
"documents_file_list_description": "Description",
"documents_file_list_date": "Date",
"documents_file_list_actions": "Actions",
"kids_memories_title": "Memories",
"kids_memories_description": "Photos, videos, little words, drawings, first big events... It is a condensed love that will follow your child all his life.",
"memories_title": "Life memories",
"memories_title_description": "This safe is not the easiest one to fill up. Here, you can talk about ",
"memories_title_description_in_between": "YOURSELF",
"memories_title_description2": " and the key steps of your life. It is all about your story, the life advices as well as the messages for your close ones and the futures generations.",
"memories_title_guardian_description": "This safe contains the most precious memories of your Wishbooker, the key steps of his life, the life advices as well as the messages for his close ones and the futures generations.",
"memories_tab_1": "My video",
"memories_tab_2": "My albums",
"memories_tab_3": "Me",
"memories_video_call_to_action_description": "With all your favorite photos and albums, Wishbook automatically generates a short video of your memories. Star each media item to include them in this video. The video will be available soon.",
"memories_video_call_to_action_checkbox": "Alert me when the video is ready",
"memories_add_collection_modal": "New album",
"member_create_specific_album": "Create a dedicated album",
"memories_add_collection_form_header_title": "Create an album",
"memories_add_collection_form_header_description": "Specify a name and a description for you album",
"memories_add_collection_form_title_label": "Name",
"memories_add_collection_form_description_label": "Description",
"memories_add_collection_form_description_placeholder": "What message do you pass on through this album?",
"memories_add_collection_form_question_mark": "The information you include in your albums will allow your close ones to know more about you were.",
"memories_add_collection_form_submit": "Create album",
"memories_add_form_success": "Album created !",
"memories_add_form_failure": "An error happened while creating this album",
"memories_in_collection_change_cover": "Change cover",
"memories_in_collection_delete": "Delete",
"memories_in_collection_edit": "Edit",
"memories_in_collection_update": "Save",
"memories_in_collection_add": "Ajouter photo",
"memories_in_collection_start_date": "Start",
"memories_in_collection_end_date": "End",
"memories_in_collection_delete_success": "Album deleted !",
"memories_in_collection_delete_failure": "An error happened while deleting this album",
"memories_in_collection_update_success": "Album updated !",
"memories_in_collection_update_failure": "An error happened while updating this album.",
"memories_in_collection_add_success": "Album created !",
"memories_in_collection_add_failure": "An error happened while creating this album",
"memories_in_collection_starred": "Added to favorites !",
"memories_in_collection_unstarred": "Removed from favorites !",
"memories_in_collection_starred_failure": "An error happened while updating favorites",
"memories_media_date": "Date",
"memories_media_description": "Description",
"memories_media_modal_title": "Add a media",
"memories_media_modal_description": "You can drop or add media items in the area below.",
"memories_in_media_submit": "Save",
"memories_in_media_submit_success": "Media updated !",
"memories_in_media_submit_failure": "An error happened while updating this media",
"memories_collection_title": "Album",
"memories_media_title": "Media",
"memories_album_delete_modal_message": "Are you sure to delete this album?",
"memories_media_delete_action": "Delete",
"memories_starred_type": "Type",
"memories_starred_name": "Name",
"memories_starred_description": "Description",
"memories_other_variant_success": "Saved !",
"memories_other_variant_failure": "An error happened while saving this...",
"memories_other_page_title": "Start my Wishbook",
"memories_other_header_title": "Wishbook starting guide",
"memories_other_header_description": "This is the starting point of your life video. Your close ones and every future generation wishing to know more about you, about were they come from, will be able to access everything you leave here. Imagine for a second if you had received all those informations from your family.",
"memories_other_form_root_title": "Who am I?",
"memories_other_form_root_origin_label": "Last name origin",
"memories_other_form_root_religion_label": "Beliefs and religions",
"memories_other_parents_checkbox_label": "I want to talk about my parents",
"memories_other_parents_title": "My parents",
"memories_other_parents_one": "Parent 1",
"memories_other_parents_two": "Parent 2",
"memories_other_family_lastname_label": "Last name",
"memories_other_family_firstname_label": "First name",
"memories_other_family_gender_label": "Gender",
"memories_other_family_gender_option_default": "Select",
"memories_other_family_gender_option_male": "Brother",
"memories_other_family_gender_option_female": "Sister",
"memories_other_family_gender_option_father": "Father",
"memories_other_family_gender_option_mother": "Mother",
"memories_other_family_dob_label": "Date of birth",
"memories_other_family_birth_location_label": "City",
"memories_other_family_origin_label": "Origins",
"memories_other_family_beliefs_label": "Beliefs and religions",
"memories_other_family_occupation_label": "Occupation",
"memories_other_family_description_label": "A few words",
"memories_other_siblings_title": "Siblings",
"memories_other_siblings_label": "Brother/Sister",
"memories_other_souvenirs_title": "My memories",
"memories_other_souvenirs_guidance_label": "Life advice for the future generation",
"memories_other_souvenirs_experience_label": "The most enriching experience",
"memories_other_souvenirs_guilty_pleasures_label": "My guilty pleasure",
"memories_other_passion_card_title": "My passions",
"memories_other_passion_label": "Describe your passions here",
"memories_other_travels_title": "My trips",
"memories_other_travels_label": "List the countries you visited",
"memories_other_lived_place_card_title": "The places I lived",
"memories_other_lived_place_address": "Address",
"memories_other_lived_place_description": "Note",
"memories_other_quotes_title": "My favorites quotes",
"memories_other_quotes_quote": "Quote",
"memories_other_quotes_author": "Author",
"memories_other_quotes_note": "Note",
"memories_other_books_card_title": "My favorite books",
"memories_other_books_title": "Title",
"memories_other_books_author": "Author",
"memories_other_books_note": "Note",
"memories_other_movies_card_title": "My favorite movies",
"memories_other_movies_title": "Title",
"memories_other_movies_author": "Director",
"memories_other_movies_description": "Note",
"memories_other_musics_card_title": "My favorite musics",
"memories_other_musics_title": "Name",
"memories_other_musics_author": "Artist",
"memories_other_musics_album": "Album",
"memories_other_musics_note": "Note",
"memories_other_recipes_card_title": "My favorite recipes",
"memories_other_recipes_title": "Name",
"memories_other_recipes_recipe": "Recipe",
"memories_other_recipes_note": "Note",
"memories_other_text_other_card_title": "To go further",
"memories_other_text_other_description_placeholder": "If you have anything more to share, this is a free text area you can use to transmit any thing that makes sense to you:",
"funerals_title": "Funerals wishes",
"funerals_title_description": "Even if talking about our down death if not an easy thing to do in some culture, this is also what makes us egual as humans before all. This topic does not need to be mentionned out loud any more with the people you love.\nFuneral wishes have a testamentary value (art 3 November 15th 1887) \"the wishes of a deceased must be perfectly respected\" An exception occurs with the olographic testament which legal value is determined by the original version.",
"funerals_organization_title": "Organization",
"funerals_organization_explanation_title": "More details on your wishes",
"funerals_organization_explanation_placeholder": "Free text",
"funerals_organization_ceremony_title": "Which type of ceremony would you like?",
"funerals_organization_ceremony_label": "Ceremony",
"funerals_organization_ceremony_type_default": "Select",
"funerals_organization_ceremony_type_1": "Religious",
"funerals_organization_ceremony_type_2": "Secular",
"funerals_organization_ceremony_type_3": "Other",
"funerals_organization_ceremony_type_other_label": "Other",
"funerals_organization_ceremony_type_other_placeholder": "Please specify which type of ceremony",
"funerals_organization_body_title": "Which type of funerals would you like?",
"funerals_organization_body_label": "Funerals",
"funerals_organization_body_description": "Cremation/Burial/Body donation to science",
"funerals_organization_body_option_default": "Select",
"funerals_organization_body_option_1": "Cremation",
"funerals_organization_body_option_2": "Burial",
"funerals_organization_body_option_3": "Body donation",
"funerals_organization_body_option_4": "Other",
"funerals_organization_body_other_placeholder": "Please specify which type of funeral",
"funerals_theme_title": "Theme",
"funerals_artefact_title": "Artefacts",
"funerals_organization_flowers_title": "Would you like some flowers?",
"funerals_organization_flowers_label": "Flowers",
"funerals_organization_flowers_option_default": "Select",
"funerals_organization_flowers_option_1": "Yes",
"funerals_organization_flowers_option_2": "No",
"funerals_organization_flowers_yes_placeholder": "If yes, my favorites",
"funerals_organization_music_title": "A song to say goodbye",
"funerals_organization_music_label": "Music/Song",
"funerals_organization_music_type_label": "Type of music",
"funerals_organization_music_type_placeholder": "Which type of music?",
"funerals_organization_color_title": "My favorite color",
"funerals_organization_color_label": "Color",
"funerals_organization_color_description_label": "Specify the color",
"funerals_organization_color_description_placeholder": "Describe the color",
"funerals_organization_artifact_title": "An object or a photograph I would like to leave with",
"funerals_organization_artifact_label": "Object/Photo",
"funerals_organization_event_title": "To honor my memory I would like you to organize together... (a particular restaurant, a family house reunion, a wake...)",
"funerals_organization_event_label": "Memory",
"funerals_organization_message_title": "A message to tell them on the day of the ceremony:",
"funerals_organization_message_label": "Message",
"funerals_organization_picture_title": "A picture of me for the ceremony",
"funerals_organization_picture_label": "Photo",
"funerals_organ_donation_title": "Organ donation",
"funerals_organ_donation_description": "Since 2017, any deceased person can be subject to organ harvesting if not registered on the national file of rejecting this practice.",
"funerals_organ_donation_step_1": "Download the official form.",
"funerals_organ_donation_step_2": "Print it.",
"funerals_organ_donation_step_3": "Fill in, date and sign it.",
"funerals_organ_donation_step_4": "Scan and drop it right below.",
"funerals_submit": "Save",
"funerals_variant_success": "Saved !",
"funerals_variant_failure": "An error happened while saving this form",
"funerals_partners_title": "",
"funerals_partners_description_format": "",
"funerals_main_partner": "",
"credentials_path_1": "Safe",
"credentials_path_2": "Credentials",
"credentials_path_3": "Edit",
"credentials_title": "Credentials",
"credentials_description": "Entrust your loved ones with the task of closing your digital accounts: taxes, energy, insurance, vehicles, telecommunications, Facebook, Twitter, Google, etc. The networks don't always allow accounts to be closed, even when a death certificate is presented, and the request is often tedious for relatives.",
"credentials_guardian_description": "Your Wishbooker gives you access to digitals accounts with all the required credentials so you can close theme once he has started the great journey. Indeed, most of the time a death certificate is not enough to close digital accounts and it becomes laborious for the families.",
"credentials_list_title": "List of your credentials to platforms and services:",
"credentials_list_empty_description": "You will find here the list of credentials that you fill in this safe.",
"credentials_add_form_button": "Add",
"credentials_add_form_title": "Create a new portal",
"credentials_add_form_title_description": "Please specify the necessary informations.",
"credentials_add_form_name_label": "Portal name",
"credentials_add_form_description_label": "Description",
"credentials_add_form_description_placeholder": "What do these credentials give access to?",
"credentials_add_form_type_option_default": "Select a type",
"credentials_add_form_type_option_1": "Phone number",
"credentials_add_form_type_option_2": "Email",
"credentials_add_form_type_option_3": "Social network",
"credentials_add_form_type_option_4": "Bank account",
"credentials_add_form_type_option_5": "Other",
"credentials_add_form_url_label": "Location",
"credentials_add_form_login_label": "Identifier / Username / Email",
"credentials_add_form_password_label": "Password",
"credentials_add_form_question_mark": "Notes and instructions (whether you want it to be closed or not)",
"credentials_add_form_submit": "Create a portal",
"credentials_update_form_title": "Update a portal",
"credentials_update_form_title_description": "Do not forget to save the modifications being made",
"credentials_submit": "Save",
"credentals_update": "Update",
"credentials_delete": "Delete",
"credentials_delete_confirm_description": "Are you sure to delete this portal?",
"credentials_add_variant_success": "Portal created !",
"credentials_add_variant_failure": "An error happened while creating a portal",
"credentials_update_variant_success": "Portal updated !",
"credentials_update_variant_failure": "An error happened while updating a portal",
"credentials_delete_variant_success": "Portal deleted !",
"credentials_delete_variant_failure": "An error happened while deleting a portal",
"directives_title": "Advance directives",
"directives_description": "Any adult person can redact some advance healthcare directives to specify what actions should be taken for their health if they are no longer able to make decisions for themselves because of illness or incapacity. This safe is always accessible to your guardians.",
"directives_guardian_description": "To express his wishes reguarding medical treatment. This is the only safe you can access during your Wishbooker's lifetime.",
"directives_step_1": "STOP TREATMENT",
"directives_step_2": "NECESSARY INTENSIVE CARE",
"directives_step_3": "SURGICAL INTERVENTION",
"directives_step_4": "STOP SUFFERING",
"directives_form_label": "I would like to fill in the form.",
"directives_form_step_1": "Download the official form.",
"directives_form_step_2": "Print-it.",
"directives_form_step_3": "Fill in, date and sign it.",
"directives_form_step_4": "Scan and drop it right below.",
"directives_save_label": "Save a copy on Wishbook",
"directives_location_title": "Where did you put the original?",
"directives_location_description": "You legatee will need the original as well.",
"directives_location_label": "Location",
"directives_description_title": "Indications",
"directives_description_text": "You can add additional instructions below.",
"directives_description_placeholder": "Free text area...",
"directives_submit": "Save",
"directives_variant_success": "Saved !",
"directives_variant_failure": "An error happened while saving the form",
"testament_title": "Olographic testament",
"testament_description": "It the easiest form of testament to create. It is legally valid if you follow the strict rules to be read carefully right below.",
"testament_guardian_description": "If your loved one has drawn up a will, you can find it here post-mortem.",
"testament_indication_title": "Indications",
"testament_indication_step_1": "Manuscript i.e. written entirely by hand (impossible to use a computer or typewriter) using an ink pen or a felt-tip pen (not a pencil), preferably on a sheet of paper white.",
"testament_indication_step_2": "Identifiable, which means that it must mention the place of drafting of the will and state the complete civil status (name, first names, date and place of birth) and the exact address of the testator and his legatees.",
"testament_indication_step_3": "Dated precisely (year expressed with four digits, month written in letters and day), a way of verifying that at this period, the testator had all his mental capacities.",
"testament_indication_step_4": "Concise and precise, written in a more direct style (\"I bequeath such and such an object to such and such a person\" instead of \"I think I will bequeath such and such an object to such and such if such a circumstance occurs\") and without additions, crossed out terms, erasures ... which could interfere with the understanding of the will.",
"testament_indication_step_5": "Signed, preferably at the end of the document rather than at the beginning, avoiding initials or only first names or nicknames (even if they allow the testator to be unambiguously identified).",
"testament_indication_step_6": "Numbered and, if it includes several pages, specifying their total number from the first page (for example, for a 4 pages document: 1/4, 2/4, 3/4, 4/4, to rule out any dispute).",
"testament_indication_step_7": "Mention the presence of a copy of your testament is being stored on your Wishbook safe as well as the physical location of the original version.",
"testament_guide_title": "How to redact a testament from home?",
"testament_guide_description": "Download your redacting guide then save it on Wishbook",
"testament_guide_cta": "Download now",
"testament_location_title": "Where did you put the original?",
"testament_location_description_text": "In order to get a legal value, it is essential to precise the current location of the original version.",
"testament_location_label": "Location",
"testament_description_title": "Instructions",
"testament_description_text": "You can add additional instructions below.",
"testament_description_placeholder": "Free text area...",
"testament_save_label": "Save a copy of your testament.",
"testament_submit": "Save",
"testament_variant_success": "Saved !",
"testament_variant_failure": "An error happened while saving the form",
"timelapse_title": "Timelapse video",
"timelapse_description": "Every birthday you take a picture of your child and after 18 years you will have an automatically generated timelapse video to relive their evolution.",
"timelapse_step_year_birth": "Birth",
"timelapse_step_year_format": "{0} year old",
"timelapse_step_years_format": "{0} years old",
"timelapse_download_action": "Download video",
"timelapse_coming_soon": "You will be notified by email when your video is ready. This feature arrives soon on Wishbook.",
"health_title": "Health",
"health_description": "Create a follow-up with up to date vaccines, next reminders, allergies, contagious diseases, blood type, medical records...",
"pot_title": "Pot",
"pot_description": "What does your child want for his or her birthday? Instead of a small gift separated, fulfill their wishes with a nice joint gift!",
"savings_title": "Savings",
"savings_description": "Add the bank details of his savings account to finance his early life as a young adult. Goals: driving licence, car, furnishing her first flat...",
"authcover_title": "",
"authcover_subtitle": "",
"authcover_arg1": "",
"authcover_arg2": "",
"authcover_review_label": "",
"authcover_press_title": "",
"login_title": "Authentication",
"login_description": "Access your Wishbook safely",
"login_reset_password": "Forgot your password ?",
"login_reset_password_reset_link": "Reset",
"login_register": "Not registered yet ?",
"login_register_link": "Click here to create your Wishbook",
"login_login": "Log in",
"login_form_login_label": "Email address",
"login_form_password_label": "Password",
"login_form_login_required": "A correct email address is required",
"login_form_password_required": "Password required",
"login_form_error_wrong_email_password": "Your email or your password is wrong",
"reset_password_title": "Reset password",
"reset_password_description": "You will receive instructions by email",
"reset_password_reset": "Reset",
"reset_password_success": "An email containing instructions has been sent to you.",
"reset_password_failure": "An error happened, please contact our customer support",
"reset_password_login": "Done ?",
"reset_password_login_link": "Click here to login",
"register_title": "Registration",
"register_description": "Before all, you need to created identifiers in order to log in.",
"register_already_login": "Already registered ?",
"register_login_link": "Click here to access Wishbook",
"register_register": "Register",
"register_form_first_name_label": "First name",
"register_form_first_name_min": "2 characters minimum",
"register_form_first_name_required": "First name required",
"register_form_last_name_label": "Last name",
"register_form_last_name_min": "2 characters minimum",
"register_form_last_name_required": "Last name required",
"register_form_email_label": "Email address",
"register_form_password_label": "Password",
"register_form_password_helper_intro": "You password must contain at least",
"register_form_password_helper_rule_1": "1 uppercase character",
"register_form_password_helper_rule_2": "1 lowercase character",
"register_form_password_helper_rule_3": "1 digit",
"register_form_password_helper_rule_4": "6 characters minimum",
"register_form_register_required": "A valid email address is required",
"register_form_password_required": "Password is required",
"register_form_marketing_optin": "I authorise Wishbook to send a newsletter",
"register_form_legals_optin": "I read the",
"register_form_legals_terms": "Terms of Use",
"register_form_legals_optin_2": "and the",
"register_form_legals_privacy_policy": "Privacy Policy",
"register_form_legals_optin_required": "You need to confirm you read the legal documents",
"register_form_error": "There is an error in the provided informations",
"register_form_error_conflict": "This user already exist, please sign in in order to access your Wishbook",
"register_email_validation_fallback": "If the link expired, click here to retry",
"register_email_validation_login": "Proceed",
"register_form_email_confirmation_label": "Email confirmation",
"regiester_form_email_confirmation_match": "This email confirmation does not match your address",
"register_form_password_confirmation_label": "Password confirmation",
"register_form_password_confirmation_match": "This field does not match your pasword",
"register_form_phone_label": "Phone number",
"register_form_dob_label": "Birthdate",
"email_validation_title": "Email confirmation",
"email_validation_description": "For safety reason, you need to confirm the email address you are using here",
"email_validation_details": "You are about to received an email in a moment, please click on the link inside in order to verify the validity of the email address so you can go further.",
"email_validation_login": "Log in",
"email_validation_logout": "Log out",
"stepper_label_step": "Step",
"onboarding_title": "Onboarding",
"stepper_1_title": "Registration",
"stepper_1_description": "Select a profile",

// Kids Wishbooker
"kids_stepper_w2_title": "Select your offer",
"kids_stepper_w2_description": "These informations are necessary to validate the payment",
"kids_stepper_w3_title": "You and your kid",
"kids_stepper_w3_description": "Who are you?",
"kids_stepper_w4_title": "Configuration",
"kids_stepper_w4_description": "Your safe needs some configuration",
"kids_stepper_w5_title": "Name your friends & family",
"kids_stepper_w5_description": "They will have access to your entire safe and they will be able to upload albums in their respective area",

// Kids Member
"kids_stepper_g2_title": "Your informations",
"kids_stepper_g2_description": "Who are you?",

// Classic Wishbooker
"classic_stepper_w2_title": "Choose your subscription",
"classic_stepper_w2_description": "These informations are necessary to validated the payment",
"classic_stepper_w3_title": "Tell us more about yourself",
"classic_stepper_w3_description": "This information will be attached to your safe",
"classic_stepper_kyc_title": "Identity document verification",
"classic_stepper_kyc_description": "These documents certify your identity",
"classic_stepper_passphrase_title": "Your safe's passphrase",
"classic_stepper_passphrase_description": "This is very important you keep it !",
"classic_stepper_members_title": "Name your guardian",
"classic_stepper_members_description": "They will have access to your safe when the time comes",
"classic_freemium_w3_title": "Please tell us more about yourself",
"classic_freemium_w3_description": "This will help us configure your Wishbook",
"classic_freemium_w4_title": "Please tell us more about yourself",
"classic_freemium_w4_description": "This will help us configure your Wishbook",
"classic_freemium_w5_title": "Please tell us more about yourself",
"classic_freemium_w5_description": "This will help us configure your Wishbook",
"classic_freemium_w6_title": "Please tell us more about yourself",
"classic_freemium_w6_description": "This will help us configure your Wishbook",
"classic_freemium_w7_title": "Name your guardian",
"classic_freemium_w7_description": "They will have access to your safe when the time comes",

// Classic Member
"classic_stepper_g2_title": "Identity document verification",
"classic_stepper_g2_description": "These documents certify your identity",
"classic_stepper_g3_title": "A unique offer, to life",
"classic_stepper_g3_description": "Wishbook is the transparency of a unique offer that guarantees the security of your legacy for a lifetime",
"onboarding_back_action": "Previous",
"onboarding_next_action": "Next",
"onboarding_start_action": "Start",
"onboarding_step_usertype_title": "Wishbooker or Relative ?",
"onboarding_step_usertype_description": "Would you like to build your own Wishbook or access someone else's? ",
"onboarding_step_usertype_wishbooker_title": "I am a Wishbooker",
"onboarding_step_usertype_wishbooker_subtitle": "I want to create my own safe of life.",
"onboarding_step_usertype_guardian_title": "I am a designated Relative",
"onboarding_step_usertype_guardian_subtitle": "A wishbooker gave me access to his safe.",
"onboarding_error_no_wishbookers": "You have to be designated by a Wishbooker in order to proceed",
"step_usertype_wishbookers_title": "Wishbookers who designated me",
"step_usertype_wishbookers_description": "Wishbookers who designated me",
"step_usertype_space_title": "Choose your space",
"step_usertype_space_description": "Each space gives you access to certain safe configurations",
"classic_step_usertype_title": "Classic",
"classic_step_usertype_description": "The Classic safe is made for +18yo",
"kid_step_usertype_title": "Kids",
"kid_step_usertype_description": "The Kids safe is made for 0-18yo",
"onboarding_step_payment_title": "A transparent subscription",
"onboarding_step_payment_description": "A simple price for your Wishbook safe",
"ob_classic_freemium_q1": "",
"ob_classic_freemium_q1_description": "",
"ob_classic_freemium_q1_opt_1": "",
"ob_classic_freemium_q1_opt_2": "",
"ob_classic_freemium_q1_opt_3": "",
"ob_classic_freemium_q1_opt_4": "",
"ob_classic_freemium_q1_opt_5": "",
"ob_classic_freemium_q1_opt_6": "",
"ob_classic_freemium_q1_opt_7": "",
"ob_classic_freemium_q1_opt_8": "",
"ob_classic_freemium_q1_opt_9": "",
"ob_classic_freemium_q1_opt_10": "",
"ob_classic_freemium_q1_opt_11": "",
"ob_classic_freemium_q1_opt_12": "",
"ob_classic_freemium_q1_opt_13": "",
"ob_classic_freemium_q1_opt_14": "",
"ob_classic_freemium_q1_opt_15": "",
"ob_classic_freemium_q2": "",
"ob_classic_freemium_q2_opt_1": "",
"ob_classic_freemium_q2_opt_2": "",
"ob_classic_freemium_q2_opt_3": "",
"ob_classic_freemium_q2_opt_4": "",
"ob_classic_freemium_q2_opt_5": "",
"ob_classic_freemium_q3": "",
"ob_classic_freemium_q3_opt_1": "",
"ob_classic_freemium_q3_opt_2": "",
"ob_classic_freemium_q3_q1": "",
"ob_classic_freemium_q4": "",
"ob_classic_freemium_q4_opt_1": "",
"ob_classic_freemium_q4_opt_2": "",
"ob_classic_freemium_q4_opt_3": "",
"ob_classic_freemium_q4_opt_4": "",
"ob_classic_freemium_q4_opt_5": "",
"ob_classic_freemium_q4_opt_6": "",
"ob_classic_freemium_q5": "",
"ob_classic_freemium_q5_opt_1": "",
"ob_classic_freemium_q5_opt_2": "",
"ob_classic_freemium_q6": "",
"ob_classic_freemium_q6_opt_1": "",
"ob_classic_freemium_q6_opt_2": "",
"ob_classic_freemium_q7": "",
"ob_classic_freemium_q7_opt_1": "",
"ob_classic_freemium_q7_opt_2": "",
"ob_classic_freemium_q7_opt_3": "",
"ob_classic_freemium_q7_opt_4": "",
"ob_classic_freemium_q7_opt_5": "",
"ob_classic_freemium_q7_opt_6": "",
"onboarding_step_kyc_title": "Identity verification",
"onboarding_step_kyc_description": "Please do not interrupt the ID verification process. The pictures being taken are not used or displayed by Wishbook.\nSelect \"Continue on mobile\" for a better user experience. ",
"onboarding_error_kyc_failure": "The identity verification has failed. Please contact our customer support.",
"step_kyc_support_error_format": "Hi, I've got a KYC error {0}",
"onboarding_step_userinfo_title": "Personal Information",
"onboarding_step_userinfo_description": "Make sure that all of your information is correct",
"step_userinfo_field_firstname": "First name",
"step_userinfo_field_lastname": "Last name",
"step_userinfo_field_dob": "Date of birth",
"step_userinfo_field_phone": "Phone",
"step_userinfo_newsletter_title": "Newsletter",
"step_userinfo_newsletter_subtitle": "I would like to received Wishbook's newsletter, there won't be spam.",
"step_userinfo_next_action": "Next",
"step_userinfo_done_action": "Finish",
"kid_user_info": "Kid's information",
"kid_gender_title": "Gender",
"kid_gender_male": "Boy",
"kid_gender_female": "Girl",
"kid_gender_other": "Other",
"kid_binding_title": "Your relationship",
"kid_binding_child": "My child",
"kid_binding_grandchild": "My grandchild",
"kid_binding_nephewniece": "My nephew/My niece",
"kid_binding_friend": "My friend's child",
"kid_binding_other": "Other",
"onboarding_step_passphrase_title": "Your passphrase",
"onboarding_step_passphrase_description": "Just like any other safe, Wishbook' safe has a key, it is your passphrase.",
"onboarding_step_passphrase_disclaimer": "Keeping this passphrase is ESSENTIAL. You need it to access your safe from anywhere, in total security. If it is lost, your data cannot be recovered.",
"onboarding_step_passphrase_intro": "Your passphrase:",
"onboarding_step_passphrase_regenerate_action": "Regenerate",
"onboarding_step_passphrase_download_action": "Download",
"onboarding_step_passphrase_checkbox": "I have downloaded the key, and I agree to keep it safe for the rest of my life in order to access my Wishbook.",
"onboarding_step_passphrase_create_action": "Open my safe",
"kid_step_configuration_title": "Configuration",
"kid_step_configuration_description": "You and your close ones can feed your safe only 4 times a year like anniversaries or year-end celebrations",
"kid_step_configuration_opening_date_title": "Define the opening dates right below. Each year it will remain accessible for 7 days starting at each one of the dates.",
"kid_step_configuration_opening_date_first": "First period",
"kid_step_configuration_opening_date_second": "Second period",
"kid_step_configuration_opening_date_third": "Third period",
"kid_step_configuration_opening_date_fourth": "Fourth period",
"kid_step_configuration_storage_member_titile": "Configure the storage capacity for each one of the members who will have access to your safe. Select 0 to let them use the whole storage capacity of the safe.",
"classic_step_guardian_title": "Name your Guardians",
"kid_step_guardian_title": "Name your Relatives",
"classic_step_guardian_description": "For Wishbook to be fully effective, you need to designate 3 guardians, each of whom will be given a security key and will only have access to your safe on presentation of a death certificate.",
"kid_step_guardian_description": "You can name as much Relatives as you want. They can see your safe's content and can participate by uploading files in the Documents or pictures in their dedicated Album.",
"step_guardian_action": "Access my safe",
"space_select_title": "Select your space",
"space_select_description": "You can see here the spaces you can access",
"space_select_passphrase_description": "Enter the passphrase you received to access your Wishbooker' space",
"space_select_title_select": "",
"space_select_title_create": "Or create your space",
"classic_space_select": "CLASSIC - THE 18+YO SAFE",
"kids_space_select": "KIDS - THE 0-18YO SAFE",
"space_select_action_create_format": "Create my {0} space",
"space_select_action_go_format": "Acess my {0} space",
"space_select_go_previous": "Previous",
"space_select_logout": "Log out",
"keyring_title": "Security",
"keyring_description": "You master the access to your safe",
"keyring_wishbooker_error": "Your passphrase does not seem to be the one you generated while creating your safe.",
"keyring_guardian_error": "This passphrase does not seem to be the one that's been transmitted to you by the Wishbooker.",
"keyring_passphrase_required": "A valid passphrase is necessary to open your safe",
"keyring_verify": "Verify",
"keyring_label": "Wishbook safe passphrase",
"keyring_lost_label": "Forgotten passphrase?",
"keyring_lost_link": "Contact us",
"keyring_logout": "Log out",
"sc_used_format": "Used storage:",
"sc_cta": "Add space",
"passphrase_notification_description": "Your passphrase must be provided to access your Wishbook safe. Please download it and keep it in a safe place, you will asked to provide it next time you log in.",
"home_title": "Wishbook | Welsome",
"home_header_hello": "Hello,",
"home_header_welcome_format": "Welcome {0},",
"home_header_welcome_guardian_format": "Welcome to {0}' space,",
"home_header_news": "What would you like to add to your legacy today?",
"home_header_my_video": "My video",
"home_header_my_complete": "Complete",
"home_safe_closed": "Your safes are now locked, select one and try to unlock it !",
"home_counter_title": "Safes closing in",
"home_counter_days": "DAYS",
"home_counter_hours": "HOURS",
"home_counter_minutes": "MINUTES",
"home_safe_card_list_title": "My safes",
"home_safe_card_guardian_list_title": "Safes",
"home_safe_card_title1": "Life memories",
"home_safe_card_desc1": "Tell the most important memories of your life",
"home_safe_card_guardian_desc1": "Your Wishbooker tells his life story",
"home_safe_card_title2": "Funerals wishes",
"home_safe_card_desc2": "What would you like for last event of your life?",
"home_safe_card_guardian_desc2": "Your Wishbooker's preferences for the last event of his life",
"home_safe_card_title3": "Advance directives",
"home_safe_card_desc3": "In case of incapacity to express your wishes, they will know",
"home_safe_card_guardian_desc3": "Your Wishbooker's instructions in case of incapicity",
"home_safe_card_title4": "Olographic testament",
"home_safe_card_desc4": "Redact your testament manually by following simple rules",
"home_safe_card_guardian_desc4": "The most simple form of testament to redact",
"home_safe_card_title5": "Official Documents",
"home_safe_card_desc5": "Transmit the legal and important documents attesting to your legacy.",
"home_safe_card_guardian_desc5": "Your Wishbooker's most important documents.",
"home_safe_card_title6": "Credentials",
"home_safe_card_desc6": "List all your digital credentials to ease your relatives' proceedings",
"home_safe_card_guardian_desc6": "Your Wishbooker's credential access and instructions",
"home_start_guide_title": "Start my Wishbook",
"home_start_guide_subtitle1": "This is the starting point of your Wishbook.",
"home_start_guide_subtitle2": "The ouput could be the most incredible video of your life.",
"home_start_guide_more_info": "More informations",
"home_start_guide_card_1": "1. My parents",
"home_start_guide_coming_soon": "Your Wishbook starting guide is coming soon...",

// // (KIDS)
"kid_safe_albums_title": "Memories",
"kid_safe_albums_desc": "Gather the most outstanding memories since his birth",
"kid_safe_albums_member_desc": "",
"kid_safe_documents_title": "Important documents",
"kid_safe_documents_desc": "List the most important documents",
"kid_safe_documents_member_desc": "",
"kid_safe_health_title": "Health book",
"kid_safe_health_desc": "Gather the major health-related information",
"kid_safe_health_member_desc": "",
"kid_safe_pot_title": "Ephemeral birthday pot",
"kid_safe_cagnotte_desc": "Common gifts for anniversaries",
"kid_safe_cagnotte_member_desc": "",
"kid_safe_savings_title": "Saving account",
"kid_safe_savings_desc": "Build and feed a saving account he will access at this majority",
"kid_safe_savings_member_desc": "",
"kid_safe_timelapse_title": "Timelapse video from 0 to 18 years old",
"kid_safe_timelapse_desc": "Create a timelapse video from his birth to his majority",
"kid_safe_timelapse_member_desc": "",
"guardian_passphrase_title": "Passphrase",
"guardian_passphrase_subtitle": "Be careful: this is your responsability to send this passphrase to your Guardian.",
"guardian_added_toast": "New guardian created !",
"guardian_cannot_be_added": "You cannot add any more Guardians",
"kid_creation_title": "Add a member",
"guardian_creation_title": "Add a guardian",
"kid_creation_subtitle": "You can designated a new Member here",
"guardian_creation_subtitle": "You can designated a new Guardian here",
"guardian_creation_field_email": "Email",
"guardian_creation_field_firstname": "First name",
"guardian_creation_field_lastname": "Last name",
"guardian_creation_field_dob": "Date of birth",
"guardian_creation_field_phone": "Phone",
"guardian_creation_close": "Close",
"guardian_creation_next": "Next",
"guardian_creation_validate": "Add",
"guardian_update_passphrase_title": "Be careful: this is your responsability to send this passphrase to your Guardian. The previous passphrase won't work anymore.",
"guardian_update_phassphrase_subtitle_format": "Passphrase update of",
"guardian_email_title": "Updated passphrase !",
"guardian_email_subtitle": "To make it easier, send an email to your Guardian containing this passphrase",
"guardian_updated_toast": "Gardien updated !",
"guardien_required_action_title": "An action is required from you",
"guardien_required_action_description": "In order to make your space even safer, we are updating the security protocole reguarding your Guardians. To do so, we need you to update every Guardian's passphrase.",
"guardien_required_action": "Update",
"guardian_passphrase_disclaimer": "You need to download the passphrase as a PDF file and check the box at the bottom of this screen in order to continue.",
"guardian_passphrase_download_action": "Download",
"guardian_passphrase_intro": "You Guardian's passphrase:",
"guardian_passphrase_instruction": "Download the document, then send it to your Guardian.",
"guardian_passphrase_checkbox": "I download and send the passphrase to my guardian.",
"guardian_passphrase_action_close": "Cancel",
"guardian_passphrase_action_save": "Save",
"guardian_send_action": "Send by email",
"guardian_action_done": "Complete",
"guardian_deletetion_confirmation_title": "Are you sure to delete this Guardian?",
"guardian_deletion_toast": "Guardian deleted !",
"guardian_list_title": "Guardians",
"guardian_add_action": "Add a Guardian",
"kid_member_card_title": "Your relatives",
"kid_member_add_action": "Add a relative",
"classic_members_title": "Guardians",
"classic_members_description": "Your guardians will be in charge of unlocking your Wishbook safe.",
"kids_members_title": "Friends & family",
"kids_members_description": "Your friends & family participate in building your kid's safe. They can access the entire safe and use the albums & documents section to upload their own content.",
"member_lock_title": "Restricted access",
"classic_member_lock_subtitle": "As a Guardian, you will not have access to this area during your Wishbooker's lifetime.",
"kids_member_lock_subtitle": "",
"classic_member_lock_action_support": "I want to declare the Wishbooker's death",
"kids_member_lock_action_support": "",
"member_lock_or": "or",
"member_to_wishbooker_action": "Create your own safe by becoming a Wishbooker",
"safe_lock_title": "Locked safes",
"classic_safe_lock_subtitle_cause_1": "You safes have been locked after being opened for more than a year",
"classic_safe_lock_subtitle_cause_2": "",
"classic_safe_lock_subtitle_cause_3": "",
"kids_safe_lock_subtitle": "",
"subscription_lock_title": "Restricted access",
"subscription_lock_subtitle": "Your current subscription does not allow you to access this section",
"subscription_lock_action_support_title": "A question? Need assistance?",
"subscription_lock_action_support_subtitle": "Contact one of our advisors immediately on the chat or let us call you back whenever is the best for you",
"subscription_lock_action_support_tchat_cta": "Start a conversation",
"subscription_lock_action_support_appointment_cta": "Schedule appointment",
"subscription_lock_support_message": "I'd like to get access to a restricted area",
"subscription_title": "Subscriptions",
"subscription_description": "",
"subscription_member_description": "",
"subscription_classic_active_user_title": "",
"subscription_classic_active_user_subtitle": "",
"subscription_classic_expired_user_title": "",
"subscription_classic_expired_user_subtitle": "",
"subscription_classic_available_title": "",
"subscription_classic_available_subtitle": "",
"subscription_payment_title": "New subscription",
"subscription_payment_description": "",
"subscription_classic_freemium_title": "Wishbook Freemium",
"subscription_classic_freemium_price": "Free",
"subscription_classic_freemium_duration": "for one month",
"subscription_classic_freemium_desc1": "End-to-end encrypted safe",
"subscription_classic_freemium_desc2": "1GO storage",
"subscription_classic_freemium_desc3": "Up to 3 Guardians",
"subscription_classic_freemium_desc4": "2 sections in your safe: funerals and documents",
"subscription_classic_premium_title": "Wishbook Classic",
"subscription_classic_premium_price": "199€",
"subscription_classic_freemium_to_premium_price": "179€",
"subscription_classic_premium_duration": "/lifetime",
"subscription_classic_premium_desc1": "End-to-end encrypted Classic safe",
"subscription_classic_premium_desc2": "2GO storage",
"subscription_classic_premium_desc3": "Up to 3 Guardians",
"subscription_classic_premium_desc4": "6 sections in your safe",
"subscription_classic_1week_title": "Safe reopening",
"subscription_classic_1week_price": "5€",
"subscription_classic_1week_duration": "/one week",
"subscription_classic_1week_desc1": "Full access to your safe during one week in order to amend it as you wish",
"subscription_classic_3members_title": "Additional guardians",
"subscription_classic_3members_price": "10€",
"subscription_classic_3members_duration": "/lifetime",
"subscription_classic_3members_desc1": "3 more person to be designated as your guardians",
"subscription_classic_2go_title": "Additional storage space",
"subscription_classic_2go_price": "39€",
"subscription_classic_2go_duration": "/lifetime",
"subscription_classic_2go_desc1": "2GO to be used in your Classic space",
"subscription_kids_premium_title": "Premium Kids",
"subscription_kids_premium_price": "180€",
"subscription_kids_premium_duration": "/up until 18yo",
"subscription_kids_premium_desc1": "1 write access to secured safe",
"subscription_kids_premium_desc2": "6GO storage",
"subscription_kids_premium_desc3": "Unlimited number of friends and family access",
"subscription_kids_premium_desc4": "6 Sections",
"subscription_kids_basic_title": "Kids basic",
"subscription_kids_basic_price": "100€",
"subscription_kids_basic_duration": "/up until 18yo",
"subscription_kids_basic_desc1": "1 write access to secured safe",
"subscription_kids_basic_desc2": "3GO storage",
"subscription_kids_basic_desc3": "Unlimited number of friends and family access",
"subscription_kids_basic_desc4": "2 Sections - Albums & Documents",
"subscription_kids_2go_title": "Additional storage space",
"subscription_kids_2go_price": "10€",
"subscription_kids_2go_duration": "/lifetime",
"subscription_kids_2go_desc1": "2GO to be used in your Kids space",
"subscription_select_stripe_payment": "One shot payment",
"subscription_select_alma_payment": "Split payments",
"subscription_select_start": "Start",
"subscription_select_finish": "Understood",
"subscription_select_success_description": "Thank you for your purchase. You can now access this area.",
"passphrase_title": "Passphrase backup",
"passphrase_wishbook_baseline": "Your legacy in the best hands, yours.",
"passphrase_intro": "This document is only addressed to the person's name displayed below.",
"passphrase_data_fullname": "Full name",
"passphrase_data_dob": "Date of birth",
"passphrase_data_notes": "Notes",
"passphrase_data_passphrase": "Passphrase",
"passphrase_info_1": "This is your access key to Wishbook.",
"passphrase_info_2": "It is highly important that you store in a safe place without anyone seeing it.",
"passphrase_info_3": "Associated to your user password, this passphrase allows you to safely access to your data from any devices.",
"passphrase_wishbook_thanks": "Wishbook warmly thank you for your trust",

// // (Checkout form)
"payment_error_format": "The payment has failed:",
"payment_customer_form_title": "Contact informations for payment",
"payment_form_email": "Email",
"payment_form_fullname": "Full name",
"payment_form_phone": "Phone",
"payment_form_address": "Address",
"payment_form_zipcode": "Zip code",
"payment_form_city": "City",
"payment_payment_form_title": "Card",
"payment_pay_action_format": "Pay",

// // (Coupon)
"payment_coupon_add_action": "Add a coupon",
"payment_coupon_title": "Pay with a coupon",
"payment_coupon_description": "You can pay with a coupon",
"payment_coupon_form_coupon": "Coupon",
"payment_coupon_error": "Your code is not valid.",
"payment_coupon_use_action": "Use the coupon",};