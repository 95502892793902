// @ts-ignore
import ed25519 from 'bcrypto/lib/ed25519';
// @ts-ignore
import random from 'bcrypto/lib/random';
// @ts-ignore
import base16 from 'bcrypto/lib/encoding/base16';

import * as bip39 from 'bip39';
import * as bip32 from 'bip32';
import * as crypto from "crypto";

import { Keyring } from 'src/api/wishbook/users/model';
import { encryptString, decryptString } from './index';

export interface KeyringOptions {
  mnemonic?: string;
  entropyLength?: number;
}

export type MasterKey = bip32.BIP32Interface;

export interface KeyPair { privateKey: string; publicKey: string; }
export interface AESKey { key: Buffer; iv: Buffer; }

const DEFAULT_DERIVATION_PATH = 'm/0/0';
const DEFAULT_ENTROPY_LENGTH = 32;
const DEFAULT_ELLIPTIC_CURVE = 'ed25519';

export const ALGO = "aes-256-cbc";
const IV = "aw90rela942f65u2";

export const generateMnemonic = (length = DEFAULT_ENTROPY_LENGTH): string => {
  const mnemonic = bip39.entropyToMnemonic(random.randomBytes(length), bip39.wordlists.french);
  console.log(mnemonic.normalize());
  return mnemonic.normalize();
};

export const generateWithMnemonicPassphrase = async(mnemonic: string): Promise<WishbookKeyring> => {

  const extractedMnemonicArray = Array.from(mnemonic.matchAll(/[A-Za-zÀ-ÿ]+/gm));
  const cleanedMnemonic = extractedMnemonicArray.join(' ');

  console.log("CLEANEDMNEMONIC");
  console.log(cleanedMnemonic);

  if (!bip39.validateMnemonic(cleanedMnemonic, bip39.wordlists.french)) {
    throw new Error('Invalid mnemonic provided!');
  }
  const seed = await bip39.mnemonicToSeed(cleanedMnemonic);
  const bip32RootKey = bip32.fromSeed(seed);
  return new WishbookKeyring(bip32RootKey, null, cleanedMnemonic);
}

const FLAG = "wishbook";
export const verifyKeyring = async (mnemonic: string, keyring: Keyring) => {
  try {
    console.log("Start verifying mnemonic");
    const localWishbookKeyring: WishbookKeyring = await generateWithMnemonicPassphrase(mnemonic);
    console.log("VERIFY KEYRING 1");
    localWishbookKeyring.feedWithEncryptedMasterKey(keyring.master_key);
    console.log("VERIFY KEYRING 2");
    encryptString("wishbook", localWishbookKeyring);
    console.log("------------------------------------------");
    const decryptedFlag = decryptString(keyring.flag, localWishbookKeyring);
    console.log(`Decrypted flag is ${decryptedFlag}`);
    if (decryptedFlag !== FLAG) {
      throw new Error('Bad flag');
    }
    return localWishbookKeyring;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export class WishbookKeyring {
  private bip32Edc25519KeyPair: KeyPair;
  public derivedKeyFromMnemonic: Buffer;

  public masterAESKey: AESKey;
  public encryptedMasterKey: Buffer;

  public passphrase: string|null;
  public hasDownloadedPassphrase: boolean;

  constructor(bip32Key: bip32.BIP32Interface, keyPair: KeyPair = null, passphrase: string = null, hasDownloadedPassphrase: boolean = true) {
    if (keyPair) { this.bip32Edc25519KeyPair = keyPair; }
    else { this.bip32Edc25519KeyPair = this.getKeyPair(bip32Key); }

    this.derivedKeyFromMnemonic = this.generateDerivedSymetricCipherFromMnemonic();
    this.passphrase = passphrase;
    this.hasDownloadedPassphrase = hasDownloadedPassphrase;
  }

  /*
  ** Local storage for easy user management
  */
  public toJson() {
    return {
      encryptedMasterKey: this.encryptedMasterKey,
      bip32Edc25519KeyPair: this.bip32Edc25519KeyPair,
      passphrase: this.passphrase,
      hasDownloadedPassphrase: this.hasDownloadedPassphrase,
    };
  }

  public static fromJson(payload) {
    console.log(`Got local keyring`);
    console.log(payload);
    if (payload && payload.bip32Edc25519KeyPair && payload.encryptedMasterKey) {
      const wishbookKeyring = new WishbookKeyring(null, payload.bip32Edc25519KeyPair, payload.passphrase, payload.hasDownloadedPassphrase);
      wishbookKeyring.feedWithEncryptedMasterKey(payload.encryptedMasterKey);
      return wishbookKeyring;
    }
    return null;  
  }

  /*
  ** Rebuild the master key from a back-end stored encrypted master key
  */
  public feedWithEncryptedMasterKey(encryptedMasterKeyHex: string) {
    console.log("-------> feedWithEncryptedMasterKey");
    console.log(encryptedMasterKeyHex);

    this.encryptedMasterKey = Buffer.from(encryptedMasterKeyHex, 'hex');

    const decipher = crypto.createDecipheriv(ALGO, this.derivedKeyFromMnemonic, Buffer.from(IV));
    let intermediateValue = decipher.update(this.encryptedMasterKey.toString("binary"), 'binary', 'utf8');
    intermediateValue += decipher.final('utf8');

    const key = Buffer.from(intermediateValue, "hex");
    this.masterAESKey = { key, iv: Buffer.from(IV) };
    console.log("[REBUILD]");
    // console.log(`Deterministic sha512 key: ${this.derivedKeyFromMnemonic.toString("hex")}`);
    console.log(`Encrypted Master key is binary->hex: ${this.encryptedMasterKey.toString('hex')}`);
    console.log(`Master key is hex: ${this.masterAESKey.key.toString("hex")}`);
  };

  /*
  ** Build an encypted version of a master key with the current deterministic context
  */
  public feedWithMasterKey(masterAESKey: AESKey) {
    this.masterAESKey = masterAESKey;

    const derivedSymetricCipher = crypto.createCipheriv(ALGO, this.derivedKeyFromMnemonic, Buffer.from(IV));
    let intermediate = derivedSymetricCipher.update(this.masterAESKey.key.toString("hex"), 'utf8', 'binary');
    intermediate += derivedSymetricCipher.final('binary');

    this.encryptedMasterKey = Buffer.from(intermediate, 'binary');
    
    console.log("[BUILD]");
    console.log(`Master key is hex: ${this.masterAESKey.key.toString("hex")}`);
    console.log(`Encrypted Master key is binary->hex: ${this.encryptedMasterKey.toString('hex')}`);
  };

  /*
  ** Create both master key and encrypted master with the current deterministic context
  */
  public createSimpleAndDerivedMasterKeyFromMnemonic = () => {
    this.masterAESKey = { key: crypto.randomBytes(32), iv: Buffer.from(IV) };

    const derivedSymetricCipher = crypto.createCipheriv(ALGO, this.derivedKeyFromMnemonic, Buffer.from(IV));
    let intermediate = derivedSymetricCipher.update(this.masterAESKey.key.toString("hex"), 'utf8', 'binary');
    intermediate += derivedSymetricCipher.final('binary');

    this.encryptedMasterKey = Buffer.from(intermediate, 'binary');
    
    console.log("[BUILD]");
    console.log(`Master key is hex: ${this.masterAESKey.key.toString("hex")}`);
    console.log(`Encrypted Master key is binary->hex: ${this.encryptedMasterKey.toString('hex')}`);
  };

  /*
  ** Create a 32bits SHA512 key from a password (which is a BIP32 to EDC25519 public key)
  */
  private generateDerivedSymetricCipherFromMnemonic = () => {
    const derivedSymetricDeterministicSHA512 = crypto.pbkdf2Sync(this.bip32Edc25519KeyPair.publicKey, 'salt', 10000, 32, 'sha512');
    console.log(`Thanks to deterministic generated from menmonic: ${derivedSymetricDeterministicSHA512.toString("hex")}`);
    return derivedSymetricDeterministicSHA512;
  };

  /*
  ** Create an ED25519 Key Pair from a BIP32 Key Pair
  */
  public getKeyPair(bip32Key: bip32.BIP32Interface): KeyPair {
    const derivationPath = DEFAULT_DERIVATION_PATH;
    const ellipticCurve = DEFAULT_ELLIPTIC_CURVE;
    const privateKey = this.derivePrivateKey(bip32Key, derivationPath);
    const publicKey = this.derivePublicKey(privateKey, ellipticCurve);
    return { privateKey, publicKey };
  }
  
  private derivePrivateKey(masterKey: MasterKey, derivationPath: string): string {
    const hdnode = masterKey.derivePath(derivationPath);
    return base16.encode(hdnode.privateKey || Buffer.from([]));
  }

  private derivePublicKey( privateKey: string, ellipticCurve = DEFAULT_ELLIPTIC_CURVE): string {
    let publicKey: Buffer;
    switch (ellipticCurve) {
      case 'ed25519':
        publicKey = ed25519.publicKeyCreate(base16.decode(privateKey), true);
        break;
      default:
        throw new Error(`Elliptic curve not supported: ${ellipticCurve}`);
    }
    return base16.encode(publicKey);
  }
}