import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export interface FileBucket {
  tus_id: string
  bucket?: string
  key?: string
}
export interface WFile {
  id: string
  updated_date?: Date,
  filetype?: string
  filename?: string
  raw_bucket?: FileBucket
  thumbnail_bucket?: FileBucket
}

export const generateFileId = () => {
  return uuidv4();
};

export const generateFilesFromUppyFiles = (files) => {
  return files.map((file) => {
    const generatedId = generateFileId();
    file.meta.file_id = file.id;

    return {
      id:  file.id,
      source: file.source,
      filetype: file.type,
      filename: file.name,
    }
  })
  ;
};

export const generateFileDocuments = (files: File[]): WFile[] => {
  console.log("FILES");
  console.log(files);
  return files.map((file) => {
    const generatedFileId = generateFileId();
    return {
      id:  generatedFileId,
      // source: file.source,
      filetype: file.type,
      filename: file.name,
      date: moment(file.lastModified).toDate(),
    };
  })
  ;
};

export const generateWFile = (file: File): WFile => {
  const generatedFileId = generateFileId();
  return {
    id:  generatedFileId,
    filetype: file.type,
    filename: file.name,
    updated_date: moment(file.lastModified).toDate(),
  };
};
